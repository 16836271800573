.workplace-page{
    width: 100vw;
    height: calc(100vh - 7rem);
    padding: 1rem;
    display: flex;
    gap: 1rem;
}

.workplace-overview{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.workplace-overview header{
  display: flex;
  padding: 0 1rem;
  padding-left: 4rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 5px;
  margin-bottom: .5rem;
  width: 100%;
}

.workplace-overview p{
  max-width: min(80vw 35rem);
}

.workplace-overview header h2{
  margin: .75rem auto;
}

.workplace-overview span{
  display: flex;
}

.invite-member{
  display: flex;
  align-items: center;
  margin: 2rem auto;
  border-radius: .5rem;
  overflow: hidden;
  border-radius: .5rem;
    border: 1px solid rgba(128, 128, 128, 0.396);
    padding: 1rem;
}

.leave-btn{
  background-color: transparent;
  border: 1px solid rgba(128, 128, 128, 0.396);
  margin: .5rem;
  border-radius: .5rem;
}
.workplace-discription{
  max-width: 30rem;
}

.member-tab{
  display: flex;
  justify-content: space-between;
  width: 10rem;
  padding: 1rem;
  border: 1px solid rgba(128, 128, 128, 0.396);
  margin: .5rem;
  border-radius: .5rem;
}

.invite-member span{
  margin-right: 1rem;
}

.invite-member input{
  background-color: var(--color-background);
  padding: 1rem;
  border: none;
  border-radius: .5rem 0 0 .5rem;
}

.invite-member button{
  background-color: var(--color-background);
  padding: 1rem;
  border: none;
  opacity: .6;
  
}

.hamburger {
    cursor: pointer;
    position: fixed;
    top: 5rem;
    left: 2rem;
    transform: scale(.75);
    opacity: .7;
    transition: all 0.5s ease-in-out;
    z-index: 999;
  }
  .hamburger:has(input:checked){
    left: 1rem;
  }
  
  .hamburger input {
    display: none;
  }
  
  .hamburger svg {
    /* The size of the SVG defines the overall size */
    height: 3em;
    /* Define the transition for transforming the SVG */
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .line {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    /* Define the transition for transforming the Stroke */
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .line-top-bottom {
    stroke-dasharray: 12 63;
  }
  
  .hamburger input:checked + svg {
    transform: rotate(-45deg);
  }
  
  .hamburger input:checked + svg .line-top-bottom {
    stroke-dasharray: 20 300;
    stroke-dashoffset: -32.42;
  }
  
.dashboard-link{
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: .5rem;
  opacity: .5;
}

.sidebar header{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 5px;
  height: 3.25rem;
  z-index: 2;
  margin: -.75rem -.75rem 1rem -.75rem;
}


.sidebar{
    position: relative;
    background-color: var(--color-background-darker);
    width: 20rem;
    height: calc(100vh - 7rem);
    border-radius: 1rem;
    padding: .75rem;
    display: flex;
    flex-direction: column;
    
}

.sidebar-wrapper{
    width: 20rem;
    min-width: 20rem;
    overflow: hidden;
    height: fit-content;
    transition: all 0.5s ease-in-out;
    border-radius: 1rem;
}

.add-branch{
  padding: 0;
  display: flex;
}

.add-branch input{
  padding: .75rem;
  flex-grow: 1;
  background-color: var(--color-background);
  border-radius: .5rem 0 0 .5rem;
  border: 1px solid rgba(128, 128, 128, 0.461);
}

.add-branch button{
  width: 3rem;
  background-color: var(--color-background);
  border-radius: 0 .5rem .5rem 0;
  font-size: 1.5rem;
  border: 1px solid rgba(128, 128, 128, 0.461);
}


.sidebar-wrapper.inactive{
    width: 0;
    min-width: 0;
    overflow: hidden;
}

.workplace-overview{
    background-color: var(--color-background-darker);
    flex-grow: 1;
    height: calc(100vh - 7rem);
    border-radius: 1rem;
}

.branch-container{
    background-color: var(--color-background-darker);
    flex-grow: 1;
    height: calc(100vh - 7rem);
    border-radius: 1rem;
    overflow: scroll;
}

.branch-container header{
  display: flex;
  align-items: center;
  font-size: large;
  justify-content: center;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 5px;
  margin-bottom: .2rem;
  z-index: 2;
  padding-left: 2rem;
}

.branch-container header button{
  border: none;
  background-color: transparent;
}

.branch-title{
  margin: auto;
}

form.branch-title{
  padding: 0;
}

.branch-title input{
  background-color: var(--color-background);
  border: none;
  border-radius: .5rem;
  font-size: large;
  font-weight: 200;
  width: 15rem;
  text-align: center;
  transition: all .1s ease-in-out;
}


/* workplace */
.workplace-btn{
    background-color: var(--color-background);
    border: none;
    border-radius: .5rem;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: .1s ease-in-out;
    word-wrap: break-word;
}




.workplace-btn:hover{
    margin-top: .2rem;
    margin-bottom: -.2rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 3px;
}

.branches-list{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    margin-top: 1rem;
    flex-grow: 1;
    overflow-y: auto;
}

.branches-list .workplace-btn{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 3px;
}



/* Leafs container */
.leafs-container{
    display: flex;
    padding: 1rem;
    max-height: calc(100% - 5rem);
    gap: 1rem;
    overflow-y: scroll;
}

.leaf{
    background-color: var(--color-background);
    padding: 1rem;
    border-radius: .5rem;
    max-width: 17.5rem;
    min-width: 17.5rem;
    height: fit-content;
}

.edit-leaf-name input{
  background-color: var(--color-background);
  border: 1px solid rgba(128, 128, 128, 0.461);
  padding: .25rem .5rem;
  border-radius: .5rem;
}

.leaf-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  border-bottom: 1px solid var(--color-background-darker);
}


.task{
  position: relative;
    background-color: var(--color-background-darker);
    padding: 1rem;
    border-radius: .2rem;
    margin-bottom: 1rem;
}

.task .tr{
  position: absolute;
  top: .5rem;
  right: .25rem;
}

.task select{
  border-radius: 1rem;
  padding-inline: .5rem;
}


.task select.low{
  background-color: rgb(175, 255, 175);
}

.task select.normal{
  background-color: rgb(255, 218, 149);
}

.task select.high{
  background-color: rgb(255, 158, 158);
}

.task-meta{
  font-family: 'Roboto', sans-serif;
  opacity: .6;
  font-size: smaller;
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-branch-btn{
  background-image:  linear-gradient(43deg, #95a5f8 20%, #7dcef4 46%);
  border: none;
  padding: .5rem;
  font-size: 1.25rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: .5rem;
  margin-bottom: .5rem;
  border-radius: .5rem;
  transition: all .1s ease-in-out;
  font-weight: 200;
}
.add-branch-btn:hover{
    margin-top: .7rem;
    margin-bottom: .3rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 3px;

}

.flex{
  display: flex;
}

.add-card{
  width: 10rem;
  height: 10rem;
  background-color: #7dcef4;
}

.add-leaf-form{
  display: flex;
  border-radius: .5rem;
  padding: 0;
  overflow: hidden;
  border: 1px solid rgba(128, 128, 128, 0.461);
}

.add-leaf-form *{
  background-color: var(--color-background-darker);
  border: none;
  padding: .75rem;
  flex-grow: 1;
}

.add-leaf-form button{
  padding: 0;
  font-size: 2rem;
  opacity: .6;
}

.add-task-form{
  display: flex;
  height: 2rem;
  overflow: hidden;
  border-radius: .5rem;
  border: 1px solid rgba(128, 128, 128, 0.461);
}

.add-task-form input{
  padding-left: .5rem;
  background-color: var(--color-background-darker);
  flex-grow: 1;
  border: none;
}

.add-task-form select{
  background-color: var(--color-background-darker);
  color: currentColor;
  padding-left: .5rem;
  font-weight: 300;
  opacity: .5;
  border: none;
}

.active.workplace-btn{
    background-color: color-mix(in srgb, var(--color-background) 50%, white 90%);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 3px;
    margin-top: .2rem;
    margin-bottom: -.2rem;
}



@media only screen and (max-width: 50rem) {
  .sidebar-wrapper{
    min-width: calc(100vw - 2rem);
  }
  .sidebar{
    width: calc(100vw - 2rem);
  }
  .leaf{
    width: calc(100vw - 5rem);
    min-width: calc(100vw - 5rem);
    
  }



}