header.top-bar{
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    border: 1px solid var(--color-background-darker);
    background-color: var(--color-background);
}

header.top-bar .logo{
    font-size: 2rem;
    font-weight: 100;
    margin-right: auto;
}

.header-placeholder{
    display: block;
    height: 4rem;
}

.notification-bell b{
    background-color: red;
    color: white;
    position: relative;
    top: -1rem;
    left: 1.75rem;
    border-radius: 1rem;
    width: 1rem;
    height: 1rem;
}

.notification-modal{
    position: fixed;
    top: 4rem;
    right: 1rem;
    width: 20rem;
    background-color: var(--color-background-darker);
    padding: 1rem;
    border-radius: .5rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid rgba(128, 128, 128, 0.396);
    z-index: 999;
    padding-top: 2rem;
}

.notification-modal>button{
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.notification-modal-content{

}

.invitation{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: .25rem;
    text-align: left;
    background-color: var(--color-background);
}

.invitation button{
    background-color: inherit;
    border: 1px solid rgba(128, 128, 128, 0.381);
    margin: .5rem;
    margin-bottom: 0;
    padding: .5rem 1rem;
}