.App {
  text-align: center;
  background-color: var(--color-background);
  min-width: 100vw;
  min-height: 100vh;
}

.App:has(.modal-backdrop.entered , .modal-backdrop.entering){
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.modal-backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  transition: 1s all;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.modal-backdrop.entering{
  opacity: 0;
}

.modal-backdrop.entered{
  opacity: 1;
}

.modal-backdrop.leaving{
  opacity: 0;
}


.modal-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  overflow: scroll;
  z-index: 999;
}

.modal{
  background-color: var(--color-background-darker);
  z-index: 2;
}

