.heading-sign-in-up{
    font-size: 3rem;
    font-weight: 400;
}

.sign-in-form.entering{
    opacity: 0;
    top: -.5rem;
    right: -5rem;
    transform: rotate(20deg);
    transform-origin: top right;
}

.sign-in-form.leaving{
    opacity: 0;
    top: 2rem;
    transform: rotate(20deg);
    transform-origin: top left;
}

.sign-in-form{
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 4rem;
    border-radius: 2.5rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid rgba(128, 128, 128, 0.396);
    gap: 1rem;
    width: min(24rem, 100vw);
    top: 0;
    right: 0;
    transition: .6s all;
}

.sign-in-form label{
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: .25rem;
}

.sign-in-form label input{
    background-color: var(--color-background);
    border: 1px solid currentColor;
    padding: .5rem 1rem;
    border-radius: .25rem;
}

.sign-in-form button{
    font-size: 1rem;
    font-weight: 700;
    color: white;
    margin-top: .5rem;
    height: 3rem;
    border: none;
    border-radius: .5rem;
    background-color: rgb(120, 139, 255);
}

.sign-in-form h2{
    margin-block: 0rem 1rem;
}

.sign-in-form .text-button{
    background-color: transparent;
    color: currentColor;
    border: none;
    font-weight: 100;
    opacity: .5;
    padding: 0;
    margin-top: -.75rem;
}

button.show-hide-button{
    position: absolute;
    color: currentColor;
    top: .5rem;
    right: .25rem;
    background-color: transparent;
    opacity: .6;
}

.error-msg{
    color: rgb(255, 117, 117);
    text-transform: lowercase;
    font-size: .7rem;
    margin-top: -.5rem;
    margin-bottom: .5rem;
    font-weight: 100;
}

.close-button{
    position: absolute;
    top: .5rem;
    right: 1rem;
    background-color: transparent !important;
    border: none;
    color: currentColor;
    opacity: .6;
}

.neg-margin{
    margin-top: -.75rem;
    text-align: left;
    margin-bottom: -1rem;
}


