@import url('https://fonts.googleapis.com/css2?family=Limelight&family=Spline+Sans+Mono:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Limelight&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&family=Spline+Sans+Mono:wght@300;400;500;600;700&display=swap');


*{
  box-sizing: border-box;
  max-width: 100vw ;
}

a{
  text-decoration: none;
  color: currentColor;
}

button{
  cursor: pointer;
}

.theme-button:checked

button{
  cursor: pointer;
}

input:focus, textarea:focus, button:focus, input, textarea, button{
  color: currentColor;
}

.f-ll{
  font-family: 'Limelight', cursive;
}

.f-ssm{
  font-family: 'Spline Sans Mono', monospace;

}


.f-r{
  font-family: 'Roboto', sans-serif;
}

.p0{
  padding: .25rem;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root:has(.theme-button:checked){
  --color-background: rgb(191, 215, 255);
  background-color:  rgb(191, 215, 255);
  color: black;
  --color-primary: black;
  --color-background-darker: rgb(166, 198, 255);
}


:root{
  --color-background: #16003B;
  background-color:  #16003B;
  color: white;
  --color-primary: white;
  --color-background-darker: #0f002a;
}

button:has(svg){
  background-color: transparent;
  border: none;
  color: currentColor;
}

.loader-container{
  width: 100vw;
  height: 100vh;
  display:grid;
  place-items: center;
}

.loader-container svg{
  fill: white;
}


